var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-8",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-6"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center mb-4"},[_c('v-icon',{staticClass:"mr-4",attrs:{"size":"36","color":"green darken-2"}},[_vm._v("mdi-sprout")]),_c('h1',{staticClass:"text-h4 font-weight-light"},[_vm._v(" Gestión de Actividades y Especies ")])],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mb-8",attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"py-4 px-6 green lighten-4"},[_c('v-icon',{attrs:{"left":"","color":"green darken-2"}},[_vm._v("mdi-leaf")]),_c('span',{staticClass:"ml-2"},[_vm._v("Actividades")]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"color":"green darken-2","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Nueva Actividad ")],1)]}}]),model:{value:(_vm.newActivityDialog),callback:function ($$v) {_vm.newActivityDialog=$$v},expression:"newActivityDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 green lighten-4"},[_vm._v(" Agregar Nueva Actividad ")]),_c('v-card-text',{staticClass:"pt-4"},[_c('v-text-field',{attrs:{"label":"Nombre de Actividad","outlined":"","dense":""},model:{value:(_vm.newActivity.name),callback:function ($$v) {_vm.$set(_vm.newActivity, "name", $$v)},expression:"newActivity.name"}}),_c('v-select',{attrs:{"items":_vm.species,"item-text":"name","item-value":"id","label":"Seleccionar Especie","outlined":"","dense":""},model:{value:(_vm.newActivity.species_id),callback:function ($$v) {_vm.$set(_vm.newActivity, "species_id", $$v)},expression:"newActivity.species_id"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){_vm.newActivityDialog = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"green darken-2","text":""},on:{"click":_vm.saveNewActivity}},[_vm._v(" Guardar ")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-0 px-2",attrs:{"headers":_vm.activityHeaders,"items":_vm.enhancedActivities,"options":{ itemsPerPage: 10 },"sort-by":['name']},on:{"click:row":_vm.handleActivityRowClick},scopedSlots:_vm._u([{key:"item.species_name",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"px-4",attrs:{"small":"","color":"grey","text-color":"white"}},[_vm._v(" "+_vm._s(item.species_name)+" ")])]}}])})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"py-4 px-6 blue lighten-4"},[_c('v-icon',{attrs:{"left":"","color":"blue darken-2"}},[_vm._v("mdi-dna")]),_c('span',{staticClass:"ml-2"},[_vm._v("Especies")]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"color":"blue darken-2","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Nueva Especie ")],1)]}}]),model:{value:(_vm.newSpeciesDialog),callback:function ($$v) {_vm.newSpeciesDialog=$$v},expression:"newSpeciesDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 blue lighten-4"},[_vm._v(" Agregar Nueva Especie ")]),_c('v-card-text',{staticClass:"pt-4"},[_c('v-text-field',{attrs:{"label":"Nombre de Especie","outlined":"","dense":""},model:{value:(_vm.newSpecies.name),callback:function ($$v) {_vm.$set(_vm.newSpecies, "name", $$v)},expression:"newSpecies.name"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){_vm.newSpeciesDialog = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"blue darken-2","text":""},on:{"click":_vm.saveNewSpecies}},[_vm._v(" Guardar ")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-0 px-2",attrs:{"headers":_vm.speciesHeaders,"items":_vm.species,"options":{ itemsPerPage: 10 },"sort-by":['name']},on:{"click:row":_vm.handleSpeciesRowClick},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"px-4",attrs:{"small":"","color":"grey","text-color":"white"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}])})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"py-4 px-6 purple lighten-4"},[_c('v-icon',{attrs:{"left":"","color":"purple darken-2"}},[_vm._v("mdi-clock-outline")]),_c('span',{staticClass:"ml-2"},[_vm._v("Períodos de Costos")]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"color":"purple darken-2","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Nuevo Período ")],1)]}}]),model:{value:(_vm.newPeriodDialog),callback:function ($$v) {_vm.newPeriodDialog=$$v},expression:"newPeriodDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 purple lighten-4"},[_vm._v(" Agregar Nuevo Período ")]),_c('v-card-text',{staticClass:"pt-4"},[_c('v-text-field',{attrs:{"label":"Nombre del Período","outlined":"","dense":""},model:{value:(_vm.newPeriod.name),callback:function ($$v) {_vm.$set(_vm.newPeriod, "name", $$v)},expression:"newPeriod.name"}}),_c('v-text-field',{attrs:{"label":"Orden Natural","type":"number","outlined":"","dense":""},model:{value:(_vm.newPeriod.naturalOrder),callback:function ($$v) {_vm.$set(_vm.newPeriod, "naturalOrder", _vm._n($$v))},expression:"newPeriod.naturalOrder"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){_vm.newPeriodDialog = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"purple darken-2","text":""},on:{"click":_vm.saveNewPeriod}},[_vm._v(" Guardar ")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-0 px-2",attrs:{"headers":_vm.periodHeaders,"items":_vm.costInputPeriods,"options":{ itemsPerPage: 10 },"sort-by":['naturalOrder']},scopedSlots:_vm._u([{key:"item.naturalOrder",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"px-2",attrs:{"small":"","color":"grey lighten-2"}},[_vm._v(" "+_vm._s(item.naturalOrder)+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"px-4",attrs:{"small":"","color":"purple lighten-4"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editPeriod(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deletePeriod(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.editPeriodDialog),callback:function ($$v) {_vm.editPeriodDialog=$$v},expression:"editPeriodDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 purple lighten-4"},[_vm._v(" Editar Período ")]),_c('v-card-text',{staticClass:"pt-4"},[_c('v-text-field',{attrs:{"label":"Nombre del Período","outlined":"","dense":""},model:{value:(_vm.editedPeriod.name),callback:function ($$v) {_vm.$set(_vm.editedPeriod, "name", $$v)},expression:"editedPeriod.name"}}),_c('v-text-field',{attrs:{"label":"Orden Natural","type":"number","outlined":"","dense":""},model:{value:(_vm.editedPeriod.naturalOrder),callback:function ($$v) {_vm.$set(_vm.editedPeriod, "naturalOrder", _vm._n($$v))},expression:"editedPeriod.naturalOrder"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){_vm.editPeriodDialog = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"purple darken-2","text":""},on:{"click":_vm.saveEditedPeriod}},[_vm._v(" Guardar ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.deletePeriodDialog),callback:function ($$v) {_vm.deletePeriodDialog=$$v},expression:"deletePeriodDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("¿Eliminar este período?")]),_c('v-card-text',[_vm._v(" Esta acción no se puede deshacer. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){_vm.deletePeriodDialog = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.confirmDeletePeriod}},[_vm._v(" Eliminar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }