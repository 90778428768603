<template>
  <v-container fluid class="px-8">
    <v-row class="mb-6">
      <v-col cols="12">
        <div class="d-flex align-center mb-4">
          <v-icon size="36" class="mr-4" color="green darken-2"
            >mdi-sprout</v-icon
          >
          <h1 class="text-h4 font-weight-light">
            Gestión de Actividades y Especies
          </h1>
        </div>
      </v-col>
    </v-row>

    <!-- Activities Section -->
    <v-row>
      <v-col cols="12">
        <v-card class="mb-8" elevation="0">
          <v-card-title class="py-4 px-6 green lighten-4">
            <v-icon left color="green darken-2">mdi-leaf</v-icon>
            <span class="ml-2">Actividades</span>
            <v-spacer></v-spacer>
            <v-dialog v-model="newActivityDialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="green darken-2"
                  dark
                  class="ml-4"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon left>mdi-plus</v-icon>
                  Nueva Actividad
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="text-h5 green lighten-4">
                  Agregar Nueva Actividad
                </v-card-title>
                <v-card-text class="pt-4">
                  <v-text-field
                    v-model="newActivity.name"
                    label="Nombre de Actividad"
                    outlined
                    dense
                  ></v-text-field>
                  <v-select
                    v-model="newActivity.species_id"
                    :items="species"
                    item-text="name"
                    item-value="id"
                    label="Seleccionar Especie"
                    outlined
                    dense
                  ></v-select>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="grey" text @click="newActivityDialog = false">
                    Cancelar
                  </v-btn>
                  <v-btn color="green darken-2" text @click="saveNewActivity">
                    Guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-title>

          <v-data-table
            :headers="activityHeaders"
            :items="enhancedActivities"
            :options="{ itemsPerPage: 10 }"
            :sort-by="['name']"
            class="elevation-0 px-2"
            @click:row="handleActivityRowClick"
          >
            <template v-slot:item.species_name="{ item }">
              <v-chip small color="grey" text-color="white" class="px-4">
                {{ item.species_name }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <!-- Species Section -->
    <v-row>
      <v-col cols="12">
        <v-card elevation="0">
          <v-card-title class="py-4 px-6 blue lighten-4">
            <v-icon left color="blue darken-2">mdi-dna</v-icon>
            <span class="ml-2">Especies</span>
            <v-spacer></v-spacer>
            <v-dialog v-model="newSpeciesDialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="blue darken-2"
                  dark
                  class="ml-4"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon left>mdi-plus</v-icon>
                  Nueva Especie
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="text-h5 blue lighten-4">
                  Agregar Nueva Especie
                </v-card-title>
                <v-card-text class="pt-4">
                  <v-text-field
                    v-model="newSpecies.name"
                    label="Nombre de Especie"
                    outlined
                    dense
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="grey" text @click="newSpeciesDialog = false">
                    Cancelar
                  </v-btn>
                  <v-btn color="blue darken-2" text @click="saveNewSpecies">
                    Guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-title>

          <v-data-table
            :headers="speciesHeaders"
            :items="species"
            :options="{ itemsPerPage: 10 }"
            :sort-by="['name']"
            class="elevation-0 px-2"
            @click:row="handleSpeciesRowClick"
          >
            <template v-slot:item.name="{ item }">
              <v-chip small color="grey" text-color="white" class="px-4">
                {{ item.name }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <!-- Cost Input Periods Section -->
    <v-row>
      <v-col cols="12">
        <v-card elevation="0">
          <v-card-title class="py-4 px-6 purple lighten-4">
            <v-icon left color="purple darken-2">mdi-clock-outline</v-icon>
            <span class="ml-2">Períodos de Costos</span>
            <v-spacer></v-spacer>
            <v-dialog v-model="newPeriodDialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="purple darken-2"
                  dark
                  class="ml-4"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon left>mdi-plus</v-icon>
                  Nuevo Período
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="text-h5 purple lighten-4">
                  Agregar Nuevo Período
                </v-card-title>
                <v-card-text class="pt-4">
                  <v-text-field
                    v-model="newPeriod.name"
                    label="Nombre del Período"
                    outlined
                    dense
                  ></v-text-field>
                  <v-text-field
                    v-model.number="newPeriod.naturalOrder"
                    label="Orden Natural"
                    type="number"
                    outlined
                    dense
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="grey" text @click="newPeriodDialog = false">
                    Cancelar
                  </v-btn>
                  <v-btn color="purple darken-2" text @click="saveNewPeriod">
                    Guardar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-title>

          <v-data-table
            :headers="periodHeaders"
            :items="costInputPeriods"
            :options="{ itemsPerPage: 10 }"
            :sort-by="['naturalOrder']"
            class="elevation-0 px-2"
          >
            <template v-slot:item.naturalOrder="{ item }">
              <v-chip small color="grey lighten-2" class="px-2">
                {{ item.naturalOrder }}
              </v-chip>
            </template>
            <template v-slot:item.name="{ item }">
              <v-chip small color="purple lighten-4" class="px-4">
                {{ item.name }}
              </v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editPeriod(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deletePeriod(item)"> mdi-delete </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <!-- Edit Period Dialog -->
    <v-dialog v-model="editPeriodDialog" max-width="500px">
      <v-card>
        <v-card-title class="text-h5 purple lighten-4">
          Editar Período
        </v-card-title>
        <v-card-text class="pt-4">
          <v-text-field
            v-model="editedPeriod.name"
            label="Nombre del Período"
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model.number="editedPeriod.naturalOrder"
            label="Orden Natural"
            type="number"
            outlined
            dense
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="editPeriodDialog = false">
            Cancelar
          </v-btn>
          <v-btn color="purple darken-2" text @click="saveEditedPeriod">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Confirmation Dialog -->
    <v-dialog v-model="deletePeriodDialog" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">¿Eliminar este período?</v-card-title>
        <v-card-text> Esta acción no se puede deshacer. </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="deletePeriodDialog = false">
            Cancelar
          </v-btn>
          <v-btn color="red" text @click="confirmDeletePeriod">
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { postRequest, putRequest, deleteRequest } from "@/managrx/serverCall";

export default {
  computed: {
    ...mapGetters(["activities", "species", "costInputPeriods"]),
    activityHeaders() {
      return [
        { text: "ID", value: "id", width: "10%" },
        { text: "Nombre de Actividad", value: "name", width: "45%" },
        { text: "Especie", value: "species_name", width: "45%" },
      ];
    },
    speciesHeaders() {
      return [
        { text: "ID", value: "id", width: "10%" },
        { text: "Nombre de Especie", value: "name", width: "90%" },
      ];
    },
    enhancedActivities() {
      return this.activities.map((activity) => {
        const foundSpecies = this.species.find(
          (s) => s.id === activity.speciesId
        );
        return {
          ...activity,
          species_name: foundSpecies?.name || "Unknown",
        };
      });
    },
    periodHeaders() {
      return [
        { text: "ID", value: "id", width: "10%" },
        { text: "Nombre", value: "name", width: "45%" },
        {
          text: "Orden Natural",
          value: "naturalOrder",
          width: "35%",
        },
        { text: "Acciones", value: "actions", width: "10%", sortable: false },
      ];
    },
  },

  data() {
    return {
      newActivityDialog: false,
      newSpeciesDialog: false,
      newActivity: {
        name: "",
        species_id: null,
      },
      newSpecies: {
        name: "",
      },
      newPeriodDialog: false,
      editPeriodDialog: false,
      deletePeriodDialog: false,
      newPeriod: {
        name: "",
        naturalOrder: null,
      },
      editedPeriod: {
        id: null,
        name: "",
        naturalOrder: null,
      },
      periodToDelete: null,
    };
  },

  mounted() {
    this.$store.dispatch("getActivities");
    this.$store.dispatch("getSpecies");
    this.$store.dispatch("getCostInputPeriods");
  },

  methods: {
    handleActivityRowClick(activity) {
      this.$router.push({
        path: `/activity/${activity.id}`,
      });
    },
    handleSpeciesRowClick(species) {
      this.$router.push({
        path: `/species/${species.id}`,
      });
    },
    async saveNewActivity() {
      let payload = {
        name: this.newActivity.name,
        speciesId: this.newActivity.species_id,
      };
      let response = await postRequest("/activity", payload);

      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.$store.dispatch("getActivities");
        this.newActivity = {
          name: "",
          species_id: null,
        };
        this.newActivityDialog = false;
      }
    },
    async saveNewSpecies() {
      let payload = {
        name: this.newSpecies.name,
      };
      let response = await postRequest("/species", payload);

      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.$store.dispatch("getSpecies");
        this.newSpecies = {
          name: "",
        };
        this.newSpeciesDialog = false;
      }
    },
    async saveNewPeriod() {
      let payload = {
        name: this.newPeriod.name,
        naturalOrder: this.newPeriod.naturalOrder,
      };

      let response = await postRequest("/cost_input_period", payload);

      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.$store.dispatch("getCostInputPeriods");
        this.newPeriod = {
          name: "",
          naturalOrder: null,
        };
        this.newPeriodDialog = false;
      }
    },
    editPeriod(item) {
      this.editedPeriod = { ...item };
      this.editPeriodDialog = true;
    },
    async saveEditedPeriod() {
      let payload = {
        name: this.editedPeriod.name,
        naturalOrder: this.editedPeriod.naturalOrder,
      };

      let response = await putRequest(
        `/cost_input_period/${this.editedPeriod.id}`,
        payload
      );

      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.$store.dispatch("getCostInputPeriods");
        this.editPeriodDialog = false;
      }
    },
    deletePeriod(item) {
      this.periodToDelete = item;
      this.deletePeriodDialog = true;
    },
    async confirmDeletePeriod() {
      let response = await deleteRequest(
        `/cost_input_period/${this.periodToDelete.id}`
      );

      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.$store.dispatch("getCostInputPeriods");
        this.deletePeriodDialog = false;
      }
    },
  },
};
</script>

<style scoped>
.v-data-table ::v-deep tbody tr {
  cursor: pointer;
  transition: background-color 0.2s;
}

.v-data-table ::v-deep tbody tr:hover {
  background-color: #f5f5f5 !important;
}

.v-card-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.v-data-table ::v-deep td {
  padding: 12px 16px !important;
}

.v-data-table ::v-deep th {
  padding: 12px 16px !important;
  font-weight: 600 !important;
}
</style> 